<template>
	<div class="people_add">
		<div class="people_add_top">
			<div class="people_add_img">
				<!-- 剪裁组件弹窗 -->
				<el-dialog
					title=""
					:visible.sync="cropperModel"
					width="1300px"
					center
					:append-to-body="true"
				>
					<template v-if="cropperModel">
						<cropper-image-new
							:Name="cropperName"
							:showType="showType"
							:showAvator="false"
							@uploadImgSuccess="handleUploadSuccess"
							ref="child"
						>
						</cropper-image-new>
					</template>
				</el-dialog>
				<img
					v-if="localPic"
					class="people_add_img_1"
					:src="localPic"
					@click="uploadImage"
				/>
				<img
					v-else
					class="people_add_img_1"
					src="https://zy.metaera.media/assets/images/active/38.png"
					@click="uploadImage"
				/>
				<img
					class="people_add_img_2"
					src="https://zy.metaera.media/assets/images/active/40.png"
					@click="uploadImage"
				/>
			</div>
			<div class="people_add_text">
				<div class="people_add_add_header">
					<span>Name Organizer</span>
				</div>
				<div class="people_add_name">
					<el-input
						placeholder="Please enter the host name"
						v-model="localName"
					>
					</el-input>
				</div>
				<div class="people_add_add_header">
					<span>Introduction Organizer</span>
				</div>
				<div class="people_add_detail">
					<el-input
						type="textarea"
						placeholder="Please enter an organizer description"
						autosize
						v-model="localContent"
					>
					</el-input>
				</div>
			</div>
			<div class="people_add_link">
				<div class="people_add_add_header">
					<span>Activity links</span>
				</div>
				<el-input
					class="people_add_input"
					v-model="localInsLink"
					placeholder="Please enter the Instagram link"
				>
					<template slot="prefix">
						<img
							src="https://zy.metaera.media/assets/images/active/35.png"
							alt="icon"
							class="input-icon"
						/>
					</template>
				</el-input>
				<el-input
					class="people_add_input"
					v-model="localTwLink"
					placeholder="Please enter the Twitter link"
				>
					<template slot="prefix">
						<img
							src="https://zy.metaera.media/assets/images/active/36.png"
							alt="icon"
							class="input-icon"
						/>
					</template>
				</el-input>
				<el-input
					class="people_add_input"
					v-model="localYtLink"
					placeholder="Please enter the YouTube link"
				>
					<template slot="prefix">
						<img
							src="https://zy.metaera.media/assets/images/active/37.png"
							alt="icon"
							class="input-icon"
						/>
					</template>
				</el-input>
				<el-input
					class="people_add_input"
					v-model="localTgLink"
					placeholder="Please enter the Telegram link"
				>
					<template slot="prefix">
						<img
							src="https://zy.metaera.media/assets/images/active/29.png"
							alt="icon"
							class="input-icon"
						/>
					</template>
				</el-input>
			</div>
		</div>
		<div class="people_add_bottom" style="display: none">
			<div class="people_add_bottom_btn" @click="confirmation()">
				CONFIRMATION
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	props: {
		currentPath: {
			type: String,
			default: "/polkadot2024",
		},
		item: {},
	},
	created() {},
	data() {
		return {
			cropperModel: false,
			cropperName: "",
			index: this.item ? this.item.index : 9999,
			isShowList: false,
			localPic: this.item ? this.item.pic : "",
			localName: this.item ? this.item.name : "",
			localContent: this.item ? this.item.content : "",
			localInsLink: this.item ? this.item.insLink : "",
			localTwLink: this.item ? this.item.twLink : "",
			localYtLink: this.item ? this.item.ytLink : "",
			localTgLink: this.item ? this.item.tgLink : "",
		};
	},
	methods: {
		uploadImage() {
			this.showType = 2;
			this.cropperName = this.localPic;
			this.cropperModel = true;
		},
		//图片上传成功后
		handleUploadSuccess(data) {
			console.log(data.url);
			this.cropperModel = false;
			this.localPic = data.url;
		},
		confirmation() {
			if (this.localPic.length == 0) {
				this.$message({ message: "请上传头像", type: "error" });
				return;
			}

			if (this.localName.length == 0) {
				this.$message({ message: "请填写名称", type: "error" });
				return;
			}

			if (this.localContent.length == 0) {
				this.$message({ message: "请填写简介", type: "error" });
				return;
			}

			const data = {
				index: this.index,
				pic: this.localPic,
				name: this.localName,
				content: this.localContent,
				insLink: this.localInsLink,
				twLink: this.localTwLink,
				ytLink: this.localYtLink,
				tgLink: this.localTgLink,
			};
			this.index = 9999;
			this.localPic = "";
			this.localName = "";
			this.localContent = "";
			this.localInsLink = "";
			this.localTwLink = "";
			this.localYtLink = "";
			this.localTgLink = "";
			this.$emit("confirmation", data);
		},
	},
	watch: {
		item(newVal) {
			this.index = this.item ? this.item.index : 9999;
			this.localPic = this.item ? this.item.pic : "";
			this.localName = this.item ? this.item.name : "";
			this.localContent = this.item ? this.item.content : "";
			this.localInsLink = this.item ? this.item.insLink : "";
			this.localTwLink = this.item ? this.item.twLink : "";
			this.localYtLink = this.item ? this.item.ytLink : "";
			this.localTgLink = this.item ? this.item.tgLink : "";
		},
	},
};
</script>

<style scoped>
.people_add {
	width: 100%;
	border-radius: 10px;
	padding-bottom: 40px;
	background: rgba(191, 191, 191, 0.08);
}
.people_add_top {
	display: flex;
	padding-top: 30px;
	padding-left: 30px;
	padding-right: 50px;
}

.people_add_img {
	position: relative;
	width: 120px;
	height: 120px;
	margin-right: 40px;
}
.people_add_img_1 {
	border-radius: 60px;
	width: 100%;
	height: 100%;
	object-fit: cover; /* 保持图像的宽高比并裁剪以填充容器 */
}

.people_add_img_2 {
	position: absolute;
	bottom: 0;
	right: 0;
	width: auto; /* 根据需要调整大小 */
	height: 34px; /* 根据需要调整大小 */
}

.people_add_text {
	flex: 1; /* 自适应宽度 */
	margin-right: 40px;
}

.people_add_add_header {
	display: flex;
	justify-content: space-between;
}

.people_add_add_header span {
	font-family: PingFang SC;
	font-size: 18px;
	font-weight: normal;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}
.people_add_name {
	margin-top: 15px;
	margin-bottom: 30px;
}
.people_add_detail {
	margin-top: 15px;
	margin-bottom: 30px;
	height: 140px;
	width: 100%;
}
.people_add_input {
	margin-top: 15px;
	align-items: center;
	align-content: center;
	height: 50px;
	border-radius: 6px;
	opacity: 1;
	background: #ffffff;
}

.people_add_link {
	width: 340px;
}

.people_add_bottom {
	margin-top: 20px;
	height: 50px;
	width: 100%;
	text-align: center;
	/* display: flex; */
}

.people_add_bottom_btn {
	border-radius: 10px;
	display: inline-block;
	opacity: 1;
	height: 50px;
	width: 200px;
	/* 主要色/主要色 */
	background: #0256ff;

	font-family: PingFang SC;
	font-size: 18px;
	font-weight: normal;
	line-height: 16px;
	text-align: center;
	align-items: center;
	align-content: center;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
</style>

<style scoped>
@media screen and (max-width: 900px) {
}
</style>

<style>
.input-icon {
	width: 26px;
	height: 26px;
}
.el-input__prefix {
	display: flex;
	align-items: center;
	margin-left: 5px;
}
.el-input--prefix .el-input__inner {
	padding-left: 40px;
}
.el-input__inner {
	/* height: 50px; */
	border-radius: 6px;
	opacity: 1;
	background: #ffffff;
	/* border: 1px solid #333333 !important; */
}

.el-textarea__inner {
	height: 100% !important;
	border-radius: 6px;
	opacity: 1;
	background: #ffffff;
	/* border: 1px solid #333333 !important; */
	padding-top: 16px;
}
.el-textarea {
	height: 100%;
}
</style>
